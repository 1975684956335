  
  .slider {
    position: relative;
    width: 300px;
    margin: auto;
    left: 0;
  }
  
  .slider img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .slider button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .slider button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  .slider button:first-of-type {
    left: 0;
  }
  
  .slider button:last-of-type {
    right: 0;
  }
  