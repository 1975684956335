  @import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Figtree:wght@500&family=Great+Vibes&family=Poppins&family=Roboto+Flex:opsz,wght@8..144,400;8..144,900&family=Tangerine:wght@700&display=swap');



*{
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
}

.active{
  display: none;
}

:root{
  --bg-color: #000000;
  --second-bg-color: #3D0000;
  --blueBackground: #2a166f;
  --text-color: #F1F6F9;
  --third-bg-color: #950101;
  --mainred-bg-color: #ee2625;
}
.mobile{
  display: none;
}
.web{
  display: block;
}
.maintainace{
  display: none;
  height: 100vh;
  text-align: center;
  margin-top: 10%;
}

.maintainlogo{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.maintainlogo img{
  width: 200px;
  height: auto;
  animation-name: App-logo-spin;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
}

.maintainace h1{
  margin-top: 27vh;
  
}
/* div {
  width: 100px;
  height: 100px;
  background-color: red;
  animation-name: example;
  animation-duration: 4s;
}

@keyframes example {
  from {background-color: red;}
  to {background-color: yellow;}
} */


.navContainer{
  display: flex;
  justify-content: space-between;
  padding: 7px 100px;
  align-items: center;
  border-bottom: 1px solid lightgray;

}
  .navlink{
    text-decoration: none;
    color: black;
  }
.navLogo img{
  width: 50px;
  height: auto;
}

.navCategories{
  display: flex;
}

.navCategories li{
  list-style-type: none;
  padding-left: 10px;
font-weight: 500;
}

.navCategories li:hover{
  color: var(--blueBackground);
  border-bottom: var(--blueBackground);
}

button{
  padding: 5px 20px;
  border-radius: 20px;
  border: none;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}
.navlistenBtn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--blueBackground);
  color: var(--text-color);
}
.navlistIcon{
  margin-left: 15px;
  margin-top: 3px;
}
.service{
  display: flex;
  cursor: pointer;
}
.dropd{
  margin-left: 5px;
}
.dropItems{
  border-radius: 10px;
  background: white;
  border: 1px solid lightgray;
  margin: 0px;
  position: absolute;
  top: 50px;
  left: 650px;
  z-index: 100;
}
.dropItems li{
  list-style: none;
  padding: 10px 35px;
  border-bottom: 1px solid lightgray;
}
.dropItems li:hover{
  background-color: #F1F6F9;
  font-weight: bold;
}

/* Section one  */

.sectionOne{
  padding: 100px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  background-color: #aea2dd;
}

.sectionOneDescription{
  padding: 30px 0px;
}
.sectionOneDescription h1{
  padding-bottom: 30px;
}
.sectionOneDescription p{
  padding-bottom: 40px;
}


.sectionOneaImg{
  /* width: 550px; */
  align-items: center;
  row-gap: 20px;
  width: 100%;
}
.homefleximg{
  display: flex;
  justify-content: space-between;
  gap: 30px;
  height: 300px;
}

.govtimg img, .honorimg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sectionOneaImg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}



/* sectionTwo  */
.sectionTwoholder{
  padding: 60px 100px;
}
.sectionTwoholder h1{
  text-align: center;
}
.desktopNewsGrid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 20px 0px;
}
.ncdimg{
  width: 100%;
  height: 270px;
  object-fit: cover;
  overflow: hidden;
  margin-bottom: 10px;
}
.ncdimg img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.viewmorebtn{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Section Three  */

.sectionThreeHolder{
  padding: 100px;
  background-color: rgb(244, 241, 239);
}

.sectionThreeHolder h1, .sectionThreeHolder P{
  text-align: center;
}
.desktopprogramGrid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 20px 0px;
}
.pcdimg{
  width: 100%;
  height: 270px;
  margin-bottom: 10px;
}

.pcdimg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardIcon{
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d3cbf0;
}
.sectionThreeGrid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 50px 0px;
}
.sectionThreeGrid h3{
  padding-top: 20px;
}
.sectionThreeGrid p{
  text-align: left;
  padding-top: 20px;
}

/* sectionFour  */
.sectionFourHolder{
  background: rgb(174,162,221);
background: linear-gradient(128deg, rgba(174,162,221,1) 0%, rgba(255,255,255,1) 35%, rgba(42,22,111,1) 100%);
padding: 100px;
}
.sectionFourHolder h1{
  text-align: center;
  padding-bottom: 10px;
}
.sectionFourHolder p{
  text-align: center;
}
.sectionFourGrid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  padding-top: 20px;
}
.sec4Card p{
  text-align: left;
}
.sec4img{
  width: 100%;
  height: 300px;
}
.sec4img img{
  width: 100%;
  height: 100%;
}
.sec4button{
  border-radius: 0;
  background-color: none;
  padding: 0;
}
.sec4btn{
  border: 1px solid var(--blueBackground);
  text-align: center;
  padding: 10px 0px;
  margin-top: 10px;
}

/* section Five  */
.sectionfiveHolder{
  display: flex;
  padding: 80px 100px;
  background-color: #aea2dd;
}

/* NEWS DETAILS PAGE */
.mobileNewsdetailsHolder{
  display: none;
}
.d_headersFont{
  font-size: 23px;
  padding: 20px 0px;
}
.newsDtails{
  padding: 0px 100px;
}
.d_dtailsBannerimage{
  display: flex;
  align-items: center;
  margin: 20px 0px;
  justify-content: space-between;
  gap: 20px;
  /* overflow-x: scroll; */
}
.d_nwsdcarddet{
  width: 400px;
  height: auto;
}
.d_dbannerimg img{
  width: 100%;
  height: 100%;
}
.newDetailsContent{
  padding: 0px 0px 10px 0px;
}
.goBACK button{
  display: flex;
  gap: 10px;
  align-items: center;
}
.d_sec3btn{
  background-color: #2a166f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Desktop Radio details  */
.mobileradioHoler{
  display: none;
}
.d_Radiobanner{
  height: 500px;
  width: 100%;
}
.desktopHider{
  background-color: var(--blueBackground);
}
.desktopHider{
  position: absolute;
  top: 72px;
  width: 100%;
}
.d_radioEVT{
  display: flex;
  align-items: center;
  padding: 20px 100px;

}

/* NEWS PAGE  */
.newHeader{
  text-align: center;
  color: var(--blueBackground);
}

.mobiletvholder {
  padding: 50px;
}

.mobiletvstack {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 60px;
}

.mtvtitle {
  text-transform: uppercase;
}

.mtvlink {
  text-decoration: none;
  color: #000000;
}

.mtvimg {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.mtvimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* podcast section */
.mpodHolder{
  text-align: center;
margin: 50px;
}
.mpodimg{
display: flex;
justify-content: center;
align-items: center;
padding: 0px 0px 10px;
margin: auto;
}
.mpodimg img{
  width: 500px;
  height: auto;
}




.Tradmark li{
  list-style: none;
  /* padding: 10px 0px 10px; */

}
.footcatGrid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 150px;

}
.futitems{
  list-style: none;
  padding: 10px 0px;
  display: flex;
}
.futitems li, .futitems1 li{
  margin-right: 10px;
}
.futitems1{
  list-style: none;
  padding: 10px 0px;
}
.d_navlink{
  color: #000000;
}
.aboutDesktop{
  display: block;
}
.aboutHolder{
  padding: 40px 100px 100px;
  text-align: justify;
}
.aboutHeader{
  text-align: center;
}
.govtholder{
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 30px 0px;
}
.govtholder img{
  width: 300px;
  height: 398px;
}
.imageDesrip{
  display: none;
}
.AboutMobile{
  display: none;
}
.destopfooter,
.desktopnavigator {
  display: block;
}

.mobilesfooter,
.mobilesnavigator {
  display: none;
}
@media(max-width: 500px){
  .mobile, .AboutMobile, .imageDesrip, .mobileradioHoler{
    display: block;
  }
  .desktop, .aboutDesktop, .govt, .dRadio{
    display: none;
  }
  .navmobile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 50px;
  }

  .m-navlink{
    text-decoration: none;
    color: black;
  }

  .mobileNavlogo img{
    width: 50px;
    height: auto;
  }
  .menuIcon{
    width: 40px;
    height: auto;
  }
  .dropDownMenuM{
    display: none;
  }
  .mobileDeactive li{
    list-style: none;
    padding: 5px 10px;
    border-bottom: 1px solid var(--blueBackground);
  }
  .mobileDeactive{
    display: block;
    background-color: white;
    padding: 10px 10px;
    position: absolute;
    top: 60;
    right: 30px;
    border: 1px solid var(--blueBackground);
    border-radius: 5px;
    z-index: 10000;
  }

  /* section1 mobile */
  .listenlivebtn{
    color: white;
    text-decoration: none;
  }
  .MobileSectoneHolder{
    padding: 10px 50px 50px;
    background-color: #aea2dd;
  }
  .mobilegovtholder{
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 30px 0px;
}
.mobilegovtImg{
  width: 150px;
  height: 198px;
  overflow: hidden;
}
.mobilegovtImg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
  .mobileSectoneDescrp h1{
    margin-top: 20px;
    margin-bottom: 20px;
  }
.mobileSectoneDescrp p{
  margin-bottom: 10px;
}
 /* section two  */
.MobilesectwoHolder{
  padding: 20px 50px 50px;
  background: rgb(174,162,221);
background: linear-gradient(128deg, rgba(174,162,221,1) 0%, rgba(255,255,255,1) 35%, rgba(42,22,111,1) 100%);
}
.MobilesectwoHolder h1{
  text-align: center;
}

.MobilesectwoHolder p{
margin-bottom: 20px;
text-align: center;
}
.mobilesectwogrid{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 97%;
  margin: auto;
}
.mcardicon2{
  border-radius: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d3cbf0;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  
}
.mobilesectwocard{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobilesectwocard p{
  text-align: left;
}
.mobileSect2Stack{
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  overflow-x: scroll;
}
.stack2card{
  width: 170px;
  height: 200px;
}
.stackTitle{
  color: #2a166f;
  padding-top: 10px;
}
.stackimg{
  width: 170px;
  height: 150px;
  overflow: hidden;
}
.stackimg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}




/* section 3  */
.mobilesec3holder{
  padding: 20px 50px 50px;
  background-color: rgb(244, 241, 239);
}
.mobilesec3holder h1{
  text-align: center;
}
.service
.sec3headerDescp{
  margin-bottom: 20px;
  text-align: center;
}

.newlink{
  color: black;
  margin-top: 20px;
}

.section3grid{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  margin: 30px 0px;
}
.sec3cardimg{
  margin-bottom: 30px;
  width: 100%;
  height: 200px;
  margin: auto;
  overflow: hidden;
}
.sec3cardimg img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sec3carddescp{
  margin-top: 10px;
}
.sec3btn{
  background-color: #2a166f;
  color: white;
}

/* Footer section mobile */

.mobilefooterholder{
  padding: 10px 50px 0;
  background-color: #aea2dd;
}
.Jomiah{
  padding: 10px 50px 0;
  background-color: white;
  display: flex;
  align-items: center;
  font-size: 12px;
}
.mobileFooter li{
  list-style: none;
}
.mobilefooterholder{
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
}

/* About section  */
.aboutHolder{
  padding: 20px 50px;
}
.m-abtheader{
  text-align: center;
}
.imageDesrip{
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}
.imageDesrip img{
  width: 150px;
  height: 248px;
}
 /* Radio section  */
.radiomobile{
width: 100%;
 height: 64vh;
}
.mobileHider{
  /* height: 70px; */
  width: 100%;
  background-color: var(--bg-color);
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 1000;
  /* padding-left: 20px; */
  display: block;
  overflow-x: hidden;
  color: white;
  font-size: 13px;
  padding: 10px 0;
  /* justify-content: space-between; */
  /* border-bottom: 1px solid whitesmoke; */
}
.time{
  color: white;
}
.mobileHiderHolder{
  width: 87%;
  margin: auto;
  display: flex;
}
.mobileHiderHolder h3{
  margin-right: 70px;
}
.radioEVT{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.radioEVT h4{
  margin-right: 10px;
}
.mobileradiofooterholder img{
  width: 150px;
  height: 150;
  object-fit: contain;
}


/* Television section  */
.mobiletvholder{
  padding: 50px;
}
.mobiletvstack{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.mtvtitle{
  text-transform: uppercase;
}
.mtvlink{
  text-decoration: none;
  color: #000000;
}
.mtvimg{
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.mtvimg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mobilefooterholdertv{
  padding: 10px 50px 0;
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  color: white;
  gap: 10px;
}
.contactIcons{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}
.termMobile{
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
}

.termMobiletv li{
  list-style: none;
}

/* Podcast channel */
.podcastContainerMob{
  display: flex;
  justify-content: center;
  padding: 50px;
}
.mpodimg{
  width: 200px;
  height: 200px;
  overflow: hidden;
}
.mpodimg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.podcastContainerMob h1{
  text-align: center;
}

/* News mobile details  */
.mobileNewsdetailsHolder{
  display: block;
}
.NewsDetailsPage{
  display: none;
}
.mNewsDetails{
  padding: 30px 50px 50px;
  text-align: justify;
}
.sec3carddescp{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.mnewDetailsContent{
  margin-bottom: 20px;
}
.m-dtailsBannerimage{
  display: flex;
  align-items: center;
  margin: 20px 0px;
  justify-content: space-between;
  gap: 20px;
  overflow-x: scroll;
}
.nwsdcarddet{
  width: 270px;
  height: 250px;
}
.mdbannerimg{
  width: 270px;
  height: 250px;
  overflow: hidden;
}
.mdbannerimg img{
width: 100%;
height: 100%;
object-fit: contain;
}
.mobile_fut_media{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
  margin: 5px 0;
}

.destopfooter, .desktopnavigator{
  display: none;
}
.mobilesfooter,.mobilesnavigator{
  display: block;
}

}









@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotate{
  100% {
    transform: rotate(360deg);
  }
}
@keyframes roll{
  100%{
    transform: rotate(360deg);
  }
}

@keyframes example {
  from {background-color: var(--bg-color);}
  to {background-color: var(--yellow-bg-color);}
}
@keyframes change{
  from{background-color: black;}
  to{background-color: var(--yellow-bg-color);}
}
