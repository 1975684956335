.adnav{
    background-color: var(--second-bg-color);
    height: 100px;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidenav_fullpage{
    display: flex;
}
.sidenav{
    width: 20%;
    border-right: 1px solid black;
    height: 100vh;
}
.sidenav li{
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
}
.sidenav li:hover{
    background-color: var(--second-bg-color);
    border: 1px solid whitesmoke;
}
.sidenav li a{
    text-decoration: none;
    color: black;
}
.sidenav li:hover a{
    color: whitesmoke;
}
.fullpage{
    width: 70%;
    margin: auto;
    margin-top: 10px;
}
.formdata{
    width: 98%;
    margin: auto;
}
.adminBtncolor{
    background-color: var(--second-bg-color);
}
.visible{
    display: block;
}
.hidden{
    display: none;
}
.welcome,.createPost,.viewpost{
    display: block;
}
.createCategory{
    margin: auto;
    margin-top: 10vh;
    margin-left: 10vw;
    /* text-align: center; */
}
.createCategory h3{
    margin-bottom: 30px;
    margin-left: 6.5vw;
}
.welcome{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30vh;
}
.adcardgrid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    width: 90%;
    margin: auto;
    padding-top: 20px;
}